/* libs/contractManagement/src/Views/retailer-acknowledgment-signature/retailer-acknowledgment-signature.component.scss */
:host legend {
  font-size: 12px;
  font-weight: unset;
  margin-bottom: 5px;
}
:host fieldset {
  margin-bottom: 8px;
}
/*# sourceMappingURL=retailer-acknowledgment-signature.component.css.map */
